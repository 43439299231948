import React from 'react'
import './splashPage.css'

export default function SplashPage() {
  return (
    <div className="splash-page-container">
      <p>Please select a chat to start messaging</p>
    </div>
  )
}
